import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'capacity';

const CapacitiesService = {
    async get() {
        return ApiService.get(resource);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.update(resource, slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async loadCapacities(params) {
        let promise = null;
        try {
            promise = await ApiService.query(resource, params);
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Laden');
        }
        return promise;
    },
    async getRequiredTimeFromOrderList(params) {
        let promise = null;
        try {
            promise = await ApiService.query(resource + '/get/requiredTimeList', params);
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Laden');
        }
        return promise;
    },
    async getRequiredTimeFromOrder(params) {
        let promise = null;
        try {
            promise = await ApiService.query(resource + '/get/requiredTime', params);
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Laden');
        }
        return promise;
    },
    async getWeeklyEmployeeTime(params) {
        let promise = null;
        try {
            promise = await ApiService.query(resource + '/get/getWeeklyEmployeeTime', params);
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Laden');
        }
        return promise;
    },
    async getCapacityForWeekFromDate(params) {
        let promise = null;
        try {
            promise = await ApiService.query(resource + '/get/capacityForWeekFromDate', params);
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Laden');
        }
        return promise;
    },
    async getMonthlyEmployeeTime(params) {
        let promise = null;
        try {
            promise = await ApiService.query(resource + '/get/getMonthlyEmployeeTime', params);
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Laden');
        }
        return promise;
    },
    async getCapacityForMonthFromDate(params) {
        let promise = null;
        try {
            promise = await ApiService.query(resource + '/get/getCapacityForMonthFromDate', params);
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Laden');
        }
        return promise;
    },
    async getTypenschildExcelData(params) {
        let promise = null;
        try {
            promise = await ApiService.query('books/get/typenschilddata', params);
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Laden');
        }
        return promise;
    },
    async saveCapacityByOrderId(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource + '/put/saveCapacityByOrderId', params);
            Toast.showSaveCapacityOkToast();
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Speichern');
        }
        return promise;
    },
    async finishTimeslot(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource + '/put/finishTimeslot', params);
            Toast.showSaveCapacityOkToast();
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Speichern');
        }
        return promise;
    },
    async shiftTimeslot(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource + '/put/shiftTimeslot', params);
            Toast.showSaveCapacityOkToast();
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Speichern');
        }
        return promise;
    },
    async saveNewTimeslot(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource + '/put/saveNewTimeslot', params);
            Toast.showSaveCapacityOkToast();
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Speichern');
        }
        return promise;
    },
    async saveTimeslots(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource + '/put/saveTimeslots', params);
            Toast.showSaveCapacityOkToast();
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Speichern');
        }
        return promise;
    },
};

export default CapacitiesService;
