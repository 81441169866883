import Vue from 'vue';
import CostlistService from '@/app/costlist/costlist.service';

const getDefaultAll = () => ({
    name: null,
    zeitaufwandAntrieb: 0,
    zeitaufwandProfilbearbeitung: 0,
    zeitaufwandVerglasen: 0,
    proFluegel: false,
    bemerkung: null,
});

const getDefaultKommissionierung = () => ({
    name: null,
    zeitaufwandProfilbearbeitung: 0,
    zeitaufwandVerglasen: 0,
    zeitaufwandVersand: 0,
    proFluegel: false,
    bemerkung: null,
});

const getDefaultAntriebstyp = () => ({
    name: null,
    zeitaufwandAntrieb: 0,
    zeitaufwandVerglasen: 0,
    proFluegel: false,
    bemerkung: null,
});

const getDefaultProfilbearbeitung = () => ({
    name: null,
    zeitaufwandProfilbearbeitung: 0,
    zeitaufwandVerglasen: 0,
    proFluegel: false,
    bemerkung: null,
});
const getDefaultOnlyProfilbearbeitung = () => ({
    name: null,
    zeitaufwandProfilbearbeitung: 0,
    proFluegel: false,
    bemerkung: null,
});

const getDefaultVerglasen = () => ({
    name: null,
    zeitaufwandVerglasen: 0,
    proFluegel: false,
    bemerkung: null,
});

const getDefaultAbwesenheit = () => ({
    start: null,
    ende: null,
});

const getDefaultMitarbeiter = () => ({
    name: null,
    arbeitszeit: {
        montag: 0,
        dienstag: 0,
        mittwoch: 0,
        donnerstag: 0,
        freitag: 0,
    },
    abwesenheiten: [],
    bemerkung: null,
});

const getDefaultBetriebsurlaub = () => ({
    start: null,
    ende: null,
    bemerkung: null,
});

const getDefaultFeiertag = () => ({
    date: null,
    name: null,
});

const getDefaultMaw = () => ({
    intMaw: 120,
    extMaw: 150,
    travelFlatRate: 100,
    versandCost: 100,
});

const getDefaultEmployeeTime = () => ({
    mitarbeiter: [],
});

const getDefaultCapacities = () => ({
    antriebstyp: [],
    sonderfunktion: [],
    verriegelung: [],
    beschlagProfilbearbeitung: [],
    beschlagVerglasen: [],
    beschlagVerpackung: 0,
    standardglaeser: [],
    sonderglaeser: [],
    bodenfuehrungZeitaufwand: 0,
    schutzfluegel: [],
    freitraeger: [],
    seitenteil: [],
    kommissionierung: 0,
});

const getDefaultItem = () => ({
    _id: null,
    maw: getDefaultMaw(),
    capacities: getDefaultCapacities(),
    employeeTime: getDefaultEmployeeTime(),
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    defaultAntriebstyp: getDefaultAntriebstyp(),
    defaultAll: getDefaultAll(),
    defaultKommissionierung: getDefaultKommissionierung(),
    defaultProfilbearbeitung: getDefaultProfilbearbeitung(),
    defaultOnlyProfilbearbeitung: getDefaultOnlyProfilbearbeitung(),
    defaultVerglasen: getDefaultVerglasen(),
    defaultAbwesenheit: getDefaultAbwesenheit(),
    defaultMitarbeiter: getDefaultMitarbeiter(),
    defaultBetriebsurlaub: getDefaultBetriebsurlaub(),
    defaultFeiertag: getDefaultFeiertag(),
});

const costlist = {
    namespaced: true,
    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await CostlistService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await CostlistService.update(client_data._id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context) {
            const { data } = await CostlistService.findFirst();
            if (data.employeeTime === null || data.employeeTime === undefined) {
                data.employeeTime = { mitarbeiter: [] };
            }
            if (data.capacities === null || data.capacities === undefined) {
                data.capacities = getDefaultCapacities;
            }
            if (data.maw === null || data.maw === undefined) {
                data.maw = getDefaultMaw;
            }
            context.commit('update', data);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        defaultAll(state) {
            return state.defaultAll;
        },
        defaultKommissionierung(state) {
            return state.defaultKommissionierung;
        },
        defaultAntriebstyp(state) {
            return state.defaultAntriebstyp;
        },
        defaultProfilbearbeitung(state) {
            return state.defaultProfilbearbeitung;
        },
        defaultOnlyProfilbearbeitung(state) {
            return state.defaultOnlyProfilbearbeitung;
        },
        defaultVerglasen(state) {
            return state.defaultVerglasen;
        },
        defaultFeiertag(state) {
            return state.defaultFeiertag;
        },
        defaultBetriebsurlaub(state) {
            return state.defaultBetriebsurlaub;
        },
        defaultMitarbeiter(state) {
            return state.defaultMitarbeiter;
        },
        defaultAbwesenheit(state) {
            return state.defaultAbwesenheit;
        },
    },
};
export { getDefaultItem };
export default costlist;
