import 'popper.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import './assets/styles/main.css';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import store from './store';
import App from './App.vue';
import { sync } from 'vuex-router-sync';
import router from './common/router';
import i18n from './i18n';
import ApiService from './common/api.service';
import Toast from './common/toast';
import moment from 'moment';
import VueMoment from 'vue-moment';
import passwordcomp from '@/components/password';
import address from '@/components/address';
import ModalDialog from '@/components/ModalDialog';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Validation from './components/Validation';
import VueBrowserUpdate from '@sum.cumo/vue-browserupdate';
import buttonspinner from '@/components/buttonspinner';
import activatesuccess from '@/components/activatesuccess';
import './registerServiceWorker';
import protocol from '@/components/protocol';
import ConfigService from '@/common/config.service';
import * as Sentry from '@sentry/vue';
import VueQrcodeReader from 'vue-qrcode-reader';

library.add(fad);
library.add(fas);

// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('font-awesome-icon', FontAwesomeIcon);
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('v-select', vSelect);
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('passwordcomp', passwordcomp);
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('addresscomp', address);
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('modal-dialog', ModalDialog);
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('validation', Validation);
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('buttonspinner', buttonspinner);
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('activatesuccess', activatesuccess);
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('protocol', protocol);

Vue.config.productionTip = false;

// Http and Auth plugins
// Vue.use(http);
// Vue.use(auth);

ApiService.init();
// Sync router to store, as `store.state.route`.
sync(store, router);

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');

Vue.filter('dob', function (value) {
    if (value) {
        return moment().diff(value, 'years');
        // Math.abs(birthday.diff(moment(), 'years'))
        // return moment(String(value)).format('MM/DD/YYYY hh:mm')
    }
});

Vue.use(Vuelidate);
Vue.use(VueLodash, { lodash });
moment.locale('de');

Vue.use(VueQrcodeReader);

Vue.use(VueMoment, { moment });
Vue.use(VueBrowserUpdate, {
    options: {
        required: { e: -1, f: -3, o: -2, s: -1, c: -3 },
        insecure: true,
        unsupported: true,
        mobile: false,
        style: 'bottom',
        reminder: 0,
        no_permanent_hide: true,
    },
});
Vue.browserUpdate.onshow(() => {});
Toast.init();

let configStage = ConfigService.getConfigIfExists('stage');
if (configStage && configStage !== 'local') {
    Sentry.init({
        Vue,
        dsn: process.env.VITE_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['https://dev.xn--prfbcher-75ac.at', 'https://pbdev.hilarion5.software', 'https://pb.hilarion5.software'],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

        environment: ConfigService.getConfigIfExists('stage'),
    });
}

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm');
    }
});

Vue.filter('formatDateDayOnly', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY');
    }
});

Vue.filter('formatDateWeekdayOnly', function (value) {
    if (value) {
        return moment(String(value)).format('dddd');
    }
});

Vue.filter('formatProtocolDate', function (value) {
    if (value) {
        return moment(value, 'DD-MM-YYYY').format('DD.MM.YYYY');
    }
});

Vue.filter('capitalize', function (value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});
