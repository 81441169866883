/* eslint-disable no-console */
import Vue from 'vue';
import router from '@/common/router';
import store from '@/store';
import auth from './index';
import JwtService from '@/common/jwt.service';
import ApiService from '@/common/api.service';
import { roleEnum, customerRoleEnum } from '@/shared/enum';

const LOGIN_URL = 'users/login';
const CODE_VALIDATION_URL = 'users/validate2fa';

// const CLIENT_SECRET = 'ZGVtb2FwcDpkZW1vcGFzcw==' // Base64(client_id:client_secret) "demoapp:demopass"

export default {
    login(creds, redirect, callback) {
        let login_json = {};
        login_json.email = creds.username;
        login_json.password = creds.password;

        Vue.axios({
            method: 'post',
            url: LOGIN_URL,
            headers: {
                // 'Authorization': 'Basic ' + CLIENT_SECRET
            },
            data: login_json,
        })
            .then((response) => {
                // make sure 401 interceptor is mounted
                ApiService.mount401Interceptor();
                if (response.status === 200 && response.data && response.data.is2FaUsed) {
                    auth.store2FaToken(response);
                    router.push({ name: 'validate2fa' });
                } else {
                    auth.storeToken(response);
                    if (redirect) {
                        if (
                            redirect === '/' &&
                            response.data.role === roleEnum().ROLE_FILIALE &&
                            response.data.customerRole !== customerRoleEnum().ROLE_DEFAULT
                        ) {
                            router.push({ name: 'customerlocation_list' });
                        } else {
                            router.push(redirect);
                        }
                    }
                    if (callback) callback(true, null);
                }
            })
            .catch((error) => {
                let errorMessage;

                if (error.response) errorMessage = error.response.data;
                else if (error.request) errorMessage = 'no response from server';
                else errorMessage = error.message;

                if (callback) callback(false, errorMessage);
            });
    },

    // refresh token, if expired
    // is 401, try to refresh access token with refresh token
    // if refresh token is expired, logout
    refreshToken() {
        const refreshToken = JwtService.getRefreshToken();
        if (refreshToken == null) {
            this.logout();
            return Promise.reject('refreshToken failed');
        }
        return ApiService.post('users/auth/refresh', { refreshToken: refreshToken })
            .then((response) => {
                JwtService.saveToken(response.data.accessToken);
                JwtService.saveRefreshToken(response.data.refreshToken);
                ApiService.setJwtHeader();
                return Promise.resolve('refreshToken success');
            })
            .catch(() => {
                this.logout();
                return Promise.reject('refreshToken failed');
            });
    },

    validate2FaToken(token, userId, redirect, callback) {
        const totp = {
            validationCode: token,
            userId: userId,
        };

        Vue.axios({
            method: 'post',
            url: CODE_VALIDATION_URL,
            data: totp,
        })
            .then((response) => {
                auth.storeToken(response);
                if (redirect) router.push(redirect);
                if (callback) callback({ success: true });
            })
            .catch((error) => {
                let returnObject = {
                    success: false,
                };
                if (error.response && error.response.status === 401) {
                    returnObject.status = 401;
                }
                if (callback) callback(returnObject);
            });
    },

    logout() {
        store.dispatch('common/clear');
        JwtService.destroyToken();
        JwtService.destroyMandant();
        ApiService.cleanUp();
        router.push({ name: 'login' });
    },
};
