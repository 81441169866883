export function roleEnum() {
    return {
        ROLE_LEITER_FERTIGUNG: 12,
        ROLE_FERTIGUNG: 11,
        ACCEPTOR_LITE_ROLE: 10,
        ROLE_SERVICE_TECHNIKER: 9,
        ROLE_FILIALE: 8,
        ROLE_LAGER: 7,
        PROJECT_ONLY_ROLE: 6,
        PROJECT_ROLE: 5,
        ACCEPTOR_ROLE: 4,
        STANDARD_ROLE: 3,
        LIST_ADMIN_ROLE: 2,
        ADMIN_ROLE: 1,
    };
}

export function customerRoleEnum() {
    return {
        ROLE_DEFAULT: 1,
        ROLE_REGIONAL_MANAGER: 2,
        ROLE_CONTROL: 3,
    };
}

export function departmentEnum() {
    return {
        AD: 4,
        VKID: 3,
        EL: 2,
        NO_DEPARTMENT: 1,
    };
}

export function customerEnum() {
    return {
        HOFER: 1,
        NO_CUSTOMER: 0,
    };
}

export function orderStatusEnum() {
    return {
        CREATED: 'CREATED',
        AKT_KW_VKID: 'AKT_KW_VKID',
        AKT_KW: 'AKT_KW',
        MONTAGE: 'MONTAGE',
        MONTAGE_ABGESCHLOSSEN: 'MONTAGE_ABGESCHLOSSEN',
        ZIVI_BESTELLT: 'ZIVI_BESTELLT',
        ZIVI_MANGEL: 'ZIVI_MANGEL',
        ZIVI_ERLEDIGT: 'ZIVI_ERLEDIGT',
        VERRECHNET: 'VERRECHNET',
    };
}

export function ampelEnum() {
    return {
        GREEN: 'green',
        ORANGE: 'orange',
        RED: 'red',
        BLACK: 'black',
    };
}

export function bookStatusEnum() {
    return {
        STATUS_INITIAL: 'STATUS_INITIAL',
        STATUS_APPROVING: 'STATUS_APPROVING',
        STATUS_APPROVED: 'STATUS_APPROVED',
        STATUS_INSPECTED: 'STATUS_INSPECTED',
        STATUS_EXPIRED: 'STATUS_EXPIRED',
        STATUS_FAILURE: 'STATUS_FAILURE',
        STATUS_TEMPLATE: 'STATUS_TEMPLATE',
    };
}

export function timeTypeEnum() {
    return {
        PROFILBEARBEITUNG: 'profilbearbeitung',
        ANTRIEB: 'antrieb',
        VERGLASEN: 'verglasen',
        VERSAND: 'versand',
        SONSTIGES: 'sonstiges',
    };
}
