import Vue from 'vue';
import { departmentEnum, customerEnum } from '@/shared/enum';
import UserService from '@/app/user/user.service';

const getDefaultState = () => ({
    isLoggedIn: false,
    accessToken: null,
    refreshToken: null,
    friendlyUsername: null,
    role: null,
    customerRole: null,
    auswertung: false,
    forecast: false,
    department: null,
    customer: null,
    userId: null,
    authorities: [],
    customerAuthorities: [],
});

const auth = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            state = Object.assign({}, getDefaultState(), data);
        },
        updateFriendlyUsername(state, data) {
            Vue.set(state, 'friendlyUsername', data);
        },
        updateAuthorities(state, data) {
            Vue.set(state, 'authorities', data);
        },
        updateCustomerAuthorities(state, data) {
            Vue.set(state, 'customerAuthorities', data);
        },
        clear(state) {
            // eslint-disable-next-line no-unused-vars
            state = Object.assign(state, getDefaultState());
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        update({ commit }, data) {
            commit('update', data);
        },
        updateFriendlyUsername(context, data) {
            context.commit('updateFriendlyUsername', data);
        },
        updateAuthorities(context, data) {
            context.commit('updateAuthorities', data);
        },
        updateCustomerAuthorities(context, data) {
            context.commit('updateCustomerAuthorities', data);
        },
        async getAuthenticatedUser(context) {
            const { data } = await UserService.get(context.state.userId);
            return data;
        },
    },

    getters: {
        hasRoleUser(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('STANDARD_ROLE')) return true;
            }
            return false;
        },
        hasRoleAdmin(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ADMIN_ROLE')) return true;
            }
            return false;
        },
        hasRoleProject(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('PROJECT_ROLE')) return true;
            }
            return false;
        },
        hasRoleProjectOnly(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('PROJECT_ONLY_ROLE')) return true;
            }
            return false;
        },
        hasRoleLager(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_LAGER')) return true;
            }
            return false;
        },
        hasRoleFiliale(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_FILIALE')) return true;
            }
            return false;
        },
        hasRoleServiceTechniker(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_SERVICE_TECHNIKER')) return true;
            }
            return false;
        },
        hasRoleFertigung(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_FERTIGUNG')) return true;
            }
            return false;
        },
        hasRoleLeiterFertigung(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_LEITER_FERTIGUNG')) return true;
            }
            return false;
        },
        hasRoleAcceptorLite(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ACCEPTOR_LITE_ROLE')) return true;
            }
            return false;
        },
        hasRoleAcceptor(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ACCEPTOR_ROLE')) return true;
            }
            return false;
        },
        hasCustomerRoleDefault(state) {
            if (Array.isArray(state.authorities)) {
                if (state.customerAuthorities.includes('ROLE_DEFAULT')) return true;
            }
            return false;
        },
        hasCustomerRoleRegionlManager(state) {
            if (Array.isArray(state.authorities)) {
                if (state.customerAuthorities.includes('ROLE_REGIONAL_MANAGER')) return true;
            }
            return false;
        },
        hasCustomerRoleControl(state) {
            if (Array.isArray(state.authorities)) {
                if (state.customerAuthorities.includes('ROLE_CONTROL')) return true;
            }
            return false;
        },
        hasDepartmentEL(state) {
            return state.department === departmentEnum().EL;
        },
        hasDepartmentVKID(state) {
            return state.department === departmentEnum().VKID;
        },
        hasKundeHofer(state) {
            return state.customer === customerEnum().HOFER;
        },
        hasRoleAuswertung(state) {
            return state.auswertung;
        },
        hasRoleForecast(state) {
            return state.forecast;
        },
    },
};

export default auth;
